<p-dialog
  class="content-space-0"
  [showHeader]="false"
  [(visible)]="issueCreditNoteInput.dialogVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100%', maxWidth: '982px' }"
>
  <div class="grid grid-nogutter">
    <div class="col-6 p-5">
      <div class="flex flex-column gap-5">
        <label class="text-2xl font-medium">{{
          'Issue Full Credit Note' | translate
        }}</label>
        <div class="flex flex-column gap-4">
          <rwa-info
            [icon]="'fa-regular fa-triangle-exclamation'"
            [type]="'warning'"
            [translationKey]="''"
            [text]="
              '<b>Note:</b> Issuing a credit note for an active subscription will lead to cancel it.'
            "
          ></rwa-info>
          <form
            [formGroup]="formGroup"
            class="flex flex-column gap-4"
          >
            <div class="flex align-items-center justify-content-center">
              <p-selectButton
                [options]="refundOptions"
                formControlName="refundValue"
                optionLabel="label"
                optionValue="value"
                (onChange)="handleSelectChange($event)"
              />
            </div>
            <div class="flex">
              <span class="showAsterisk w-full">
                <p-calendar
                  inputId="issueDate-input"
                  id="issueDate-input"
                  placeholder="Issue Date"
                  [minDate]="minDate"
                  [maxDate]="minDate"
                  [showIcon]="true"
                  [readonlyInput]="true"
                  icon="fa-regular fa-calendar"
                  styleClass="w-full"
                  [showClear]="true"
                  appendTo="body"
                  formControlName="issueDate"
                ></p-calendar>
              </span>
            </div>
            <div class="flex">
              <span class="showAsterisk w-full">
                <p-dropdown
                  styleClass="w-full"
                  [options]="dropdownItems"
                  formControlName="refundReason"
                  placeholder="Select a Refund Reason"
                >
                </p-dropdown>
              </span>
            </div>
            <div class="flex flex-column gap-1">
              <textarea
                class="textarea-resize-none"
                placeholder="Notes"
                rows="5"
                pInputTextarea
                formControlName="notes"
              >
              </textarea>
              @if (notes?.errors?.maxlength) {
                <span class="text-red-60 text-xs">
                  <small>Input must not exceed 500 characters.</small>
                </span>
              }
              <div
                class="flex align-items-center justify-content-end text-neutral-70 text-xs"
              >
                {{ getRefundNoteValue().length }}/500
              </div>
            </div>
            <!-- <div class="flex align-items-center justify-content-center">
              <span class="border-top-1 border-neutral-20 w-full"></span>
              <p-button
                label="More Details"
                [outlined]="true"
                [rounded]="true"
                icon="fa-regular fa-angles-down"
                styleClass="border-neutral-20 white-space-nowrap w-full"
              />
              <span class="border-top-1 border-neutral-20 w-full"></span>
            </div> -->
          </form>
        </div>
      </div>
    </div>
    <div class="col-6 p-5 bg-neutral-10 border-neutral-20 border-left-1">
      <div class="flex flex-column gap-5">
        <div class="flex align-items-center justify-content-between">
          <label class="text-2xl font-medium">{{
            'Credit Note summary' | translate
          }}</label>
          <p-button
            icon="fa-regular fa-xmark"
            [rounded]="true"
            [text]="true"
            (click)="close()"
          />
        </div>
        <div class="flex flex-column">
          <div class="rwa__datatable-transparent">
            <p-table
              [value]="productList"
              styleClass="text-sm"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">{{ 'Product Name' | translate }}</th>
                  <th class="text-center">{{ 'Quantity' | translate }}</th>
                  <th class="text-right">{{ 'Adjusted Price' | translate }}</th>
                  <th class="text-right">{{ 'Total' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-product
              >
                <tr>
                  <td class="text-left">
                    <p-checkbox
                      [binary]="true"
                      [disabled]="true"
                      [(ngModel)]="itemsChecked"
                    ></p-checkbox>
                  </td>
                  <td class="text-left">{{ product.name | translate }}</td>
                  <td class="text-center">{{ product.quantity }}</td>
                  <td class="text-right">{{ product.adjustedPrice }}</td>
                  <td class="text-right">{{ product.totalPrice }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <hr
            class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
          />
          <div class="flex justify-content-end mt-3">
            <div class="flex flex-column gap-3 w-9">
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Subtotal' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.subtotal }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Discount' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.discount }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{
                    'Promotional Credits' | translate
                  }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Exclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.promotions }}</span>
              </div>
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{ 'Taxes' | translate }}</label>
                </div>
                <span>{{ invoiceData.tax }}</span>
              </div>
              <hr
                class="border-neutral-20 border-top-1 border-bottom-none border-x-0 m-0"
              />
              <div
                class="flex text-sm align-items-center justify-cotnent-between text-neutral-90 w-full"
              >
                <div class="flex align-items-center gap-2 w-full">
                  <label class="font-medium">{{ 'Total' | translate }}</label>
                  <span class="text-neutral-70"
                    >({{ 'Tax Inclusive' | translate }})</span
                  >
                </div>
                <span>{{ invoiceData.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-end gap-3">
      <p-button
        label="{{ 'Cancel' | translate }}"
        [text]="true"
        (click)="close()"
      ></p-button>
      <p-button
        label="{{ 'Issue Credit Note' | translate }}"
        [disabled]="formGroup.invalid || issuingCreditNote"
        (click)="issueCreditNote()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { Router } from '@angular/router';
import {
  CreateCreditNoteResponseDTO,
  CreditNoteLineItemDTO,
  CreditNoteRequestForPartiallyPaidInvoice,
  IssueCreditNoteDTO,
  MappedBillingDocument,
} from '../../../../accounts/types';
import { AccountService } from '../../../../accounts/account.service';
import { CustomToastService } from '../../../services/custom-toast.service';
import { CalculatorService } from '../../../services/calculator.service';
import { InvoiceZatcaStatusConstantOnFE } from '../../../types';
import { IssueCreditNoteFlowType } from '../../../enums/issue-credit-note-flowtype.enum';
import { PopupData } from '../../../types/popup-data.interface';

@Component({
  selector: 'rwa-issue-credit-note-partial-payment',
  templateUrl: './issue-credit-note-partial-payment.component.html',
  styleUrl: './issue-credit-note-partial-payment.component.scss',
})
export class IssueCreditNotePartialPaymentComponent
  implements OnInit, OnChanges
{
  constructor(
    readonly accountService: AccountService,
    readonly customToastService: CustomToastService,
    readonly router: Router,
  ) {}

  @Input() issueCreditNoteInput: {
    dialogVisible: boolean;
    email: string;
    flowType: IssueCreditNoteFlowType;
    invoiceData: MappedBillingDocument;
    subscriptionId?: string;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeDialog = new EventEmitter<any>();

  @Output() issueCreditNoteSuccess = new EventEmitter<void>();

  formGroup!: FormGroup;

  creditNoteIssueDate: Date = new Date();

  issuingCreditNote: boolean = false;

  invoiceData: IssueCreditNoteDTO = {
    subtotal: 0,
    discount: 0,
    promotions: 0,
    tax: 0,
    total: 0,
  };

  productList: CreditNoteLineItemDTO[] = [];

  refundOptions: SelectItem[] = [
    { label: 'Full Refund', value: 'full' },
    { label: 'Prorated Refund', value: 'prorated', disabled: true },
  ];

  refundReasonsDropdownItems: SelectItem[] = [
    { label: 'New Subscription Refund', value: 'New Subscription Refund' },
    { label: 'Renew Subscription Refund', value: 'Renew Subscription Refund' },
    { label: 'New Invoice Cancellation', value: 'New Invoice Cancellation' },
    {
      label: 'Renew Invoice Cancellation',
      value: 'Renew Invoice Cancellation',
    },
    { label: 'Not Paid', value: 'Not Paid' },
    { label: 'Order Change', value: 'Order Change' },
    { label: 'Order Refund', value: 'Order Refund' },
    { label: 'Order Cancellation', value: 'Order Cancellation' },
    { label: 'Retrieve Device', value: 'Retrieve Device' },
  ];

  refundTypesDropdownItems: SelectItem[] = [
    { label: 'Adjustment Credit Note', value: 'ADJUSTMENT' },
    { label: 'Refundable Credit Note', value: 'REFUNDABLE' },
  ];

  ngOnInit(): void {
    this.initializeForm();
    this.handleDropdownState();
    this.setProductList();
    this.setInvoiceData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.issueCreditNoteInput &&
      changes.issueCreditNoteInput.currentValue.dialogVisible === true
    ) {
      this.handleDropdownState();
      this.setProductList();
      this.setInvoiceData();
    }
  }

  initializeForm(): void {
    this.formGroup = new FormGroup({
      refundValue: new FormControl('full'),
      notes: new FormControl(null, Validators.maxLength(500)),
      refundReason: new FormControl(null, Validators.required),
      refundType: new FormControl(null, Validators.required),
    });
  }

  public get refundValue(): string {
    return this.formGroup.get('refundValue')?.value;
  }

  public get notes(): AbstractControl {
    return this.formGroup.get('notes');
  }

  public get refundReason(): string {
    return this.formGroup.get('refundReason')?.value;
  }

  public get refundType(): string {
    return this.formGroup.get('refundType')?.value;
  }

  public get selectedRefundTypeValue(): string {
    const selectedItem = this.refundType;
    if (!selectedItem) return '';
    return selectedItem.toLowerCase();
  }

  public get getCreditNoteAmountByType(): number {
    const selectedItem = this.refundType;

    if (!selectedItem) return 0;
    if (selectedItem === 'ADJUSTMENT')
      return this.issueCreditNoteInput.invoiceData.dueAmount / 100;
    if (selectedItem === 'REFUNDABLE')
      return this.issueCreditNoteInput.invoiceData.amountPaid / 100;

    return 0;
  }

  handleDropdownState(): void {
    if (
      this.issueCreditNoteInput.invoiceData.zatcaStatus ===
      InvoiceZatcaStatusConstantOnFE.Rejected
    ) {
      const reason =
        this.issueCreditNoteInput.invoiceData.invoice_type === 'B2B'
          ? 'ZATCA Rejected Invoice'
          : 'ZATCA Failure';
      this.refundReasonsDropdownItems.push({
        label: reason,
        value: reason,
      });
      this.formGroup.get('refundReason')?.setValue(reason);
      this.formGroup.get('refundReason')?.disable();
    } else {
      this.refundTypesDropdownItems[0].disabled =
        this.issueCreditNoteInput.invoiceData.adjustment_credit_notes.length >
        0;
      this.refundTypesDropdownItems[1].disabled =
        this.issueCreditNoteInput.invoiceData.issued_credit_notes.length > 0;
      this.formGroup.get('refundReason')?.enable();
      this.formGroup.get('refundType')?.enable();
    }
  }

  issueCreditNote(): void {
    const { id, lineItems } = this.issueCreditNoteInput.invoiceData;
    const plan = lineItems.find((item) => item.type === 'plan');
    const payload: CreditNoteRequestForPartiallyPaidInvoice = {
      reference_invoice_id: id,
      plan_id: plan?.id,
      date: Math.floor(new Date(this.creditNoteIssueDate).getTime() / 1000),
      create_reason_code: this.refundReason,
      customer_notes: this.notes.value,
      creditNoteType: this.refundType,
    };

    this.issuingCreditNote = true;
    this.accountService
      .issueCreditNoteForPartiallyPaidInvoice(payload)
      .subscribe({
        next: (response: CreateCreditNoteResponseDTO) => {
          if (
            this.issueCreditNoteInput.flowType ===
            IssueCreditNoteFlowType.FROM_INVOICE_TABLE
          ) {
            const popupData: PopupData = {
              popupHeadingMsg: 'Credit Note Issued Successfully',
              popupMsg: 'You can see the credit note in the billing history',
              planWarning: !!plan?.id,
              email: this.issueCreditNoteInput.email,
              creditNoteId: response.creditNoteId,
            };
            this.close(popupData);
            return;
          }
          this.close();
          this.routeToAccountList(plan?.id);
        },
        error: (err) => {
          this.issuingCreditNote = false;
          this.customToastService.error(err.message);
        },
      });
  }

  setProductList(): void {
    this.productList = this.issueCreditNoteInput.invoiceData.lineItems.map(
      (item) => ({
        name: item.name,
        quantity: item.quantity,
        adjustedPrice: CalculatorService.divide(
          item.unitAmount + item.taxAmount,
          100,
        ),
        totalPrice: CalculatorService.divide(
          item.unitAmount + item.taxAmount,
          100,
        ),
      }),
    );
  }

  setInvoiceData(): void {
    this.invoiceData = {
      subtotal: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.subTotal,
        100,
      ),
      discount: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.discount,
        100,
      ),
      promotions: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.creditsApplied,
        100,
      ),
      tax: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.tax,
        100,
      ),
      total: CalculatorService.divide(
        this.issueCreditNoteInput.invoiceData.total,
        100,
      ),
    };
  }

  routeToAccountList(planId?: string): void {
    const { email, subscriptionId } = this.issueCreditNoteInput;
    this.router.navigate(['/accounts/list'], {
      state: {
        email,
        subscriptionId,
        popupHeadingMsg: 'Credit Note Issued Successfully',
        popupMsg: 'You can see the credit note in the billing history',
        planWarning: !!planId,
      },
    });
  }

  getRefundNoteValue(): string {
    return this.formGroup.get('notes')?.value || '';
  }

  handleSelectChange(event: SelectButtonChangeEvent): void {
    const selectedValue = event.value;
    if (selectedValue !== 'full') {
      this.formGroup.controls.refundValue.setValue('full');
    }
  }

  close(popupData?: PopupData): void {
    this.formGroup.reset();
    this.formGroup.controls.refundValue.setValue('full');
    if (popupData) this.closeDialog.emit(popupData);
    else this.closeDialog.emit();
  }
}

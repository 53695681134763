import { ItemEntitlements, PaymentDTO, PaymentType } from '@rewaa-team/types';
import { SecondaryIDEnum } from '@rewaa-team/rewaa-common';
import { LinkedPayment } from 'chargebee-typescript/lib/resources/invoice';
import { CouponApplicabilityStatus, ShippingAddressTypes } from './constants';
// eslint-disable-next-line import/no-cycle
import { CbCreditNoteData, ZatcaMessage } from '../shared/types';

export interface AccountDTO {
  id: number;
  email: string;
  companyName: string;
  schemaName: string;
  status: string;
  phone: string;
  subscriptionStatus?: string;
  lastInvoiceDate?: number;
  lastInvoiceFormattedDate?: string;
}

export interface PaginatedAccountListDTO {
  accountList: AccountDTO[];
  totalRecords: number;
}

export interface InstalledApp {
  id: number;
  name: string;
}

export interface AllowedApp {
  featureId: string;
  featureName: string;
  isPlanEntitlement: boolean;
}

export interface AccountDetailsDTO {
  merchant: MerchantDetail;
  subscription: SubscriptionDetail | undefined;
  crm: CrmDetails;
}

export interface MerchantDetail {
  id: number;
  status: string;
  email: string;
  companyName: string;
  companyType: string;
  companyAddress: string;
  phoneNumber: string;
  schemaName: string;
  vatNumber: string;
  crNumber: string;
  activeLocationCount: number;
  numberOfUsedSKUs: number;
  accountCreationDate: Date | string;
  lastLogin: string;
  users: MerchantUserWithRole[];
}
export interface MerchantUserWithRole {
  id: number;
  userName: string;
  role: {
    id: number;
    name: string;
  }[];
  email: string;
  status: string;
  lastLogin: Date | null;
}

export interface CrmDetails {
  accountId: string;
  email: string;
  onboardingStatus?: string;
  leadOwner?: string;
  leadSource?: string;
  publisher?: string;
  channel?: string;
  referralEmail?: string;
  csOwner?: string;
  isChurned?: boolean | string;
  churnDate?: unknown;
  churnReason?: string;
  IsRefunded?: boolean | string;
  refundDate?: unknown;
  refundReason?: string;
  phoneNumber?: string;
  companyName?: string;
}

export interface SubscriptionDetail {
  subscriptionId: string;
  subscriptionStartDate: unknown;
  planName: string;
  customerId: string;
  activeUsersCount: number;
  allowedApps: AllowedApp[];
  installedApps: InstalledApp[];
  subscriptionStatus: string;
  nextBillingDate: unknown;
  nextBillingAmount: number;
  allowedCustomFields: number;
  allowedLocations: number;
  period?: number;
}

export interface BillingDocumentLineItem {
  id: string;
  itemId?: string;
  type: string;
  name: string;
  quantity: number;
  unitAmount: number;
  amount: number;
  taxAmount: number;
  taxRate?: number;
  discountAmount?: number;
  period?: number;
  unit?: string;
  taxableAmount?: number;
  dateFrom?: number;
  dateTo?: number;
  internalName?: string;
  externalName?: string;
}

export interface CreditNoteRequest {
  reference_invoice_id: string;
  plan_id?: string;
  date?: number;
  create_reason_code: string;
  customer_notes: string;
}

export interface CreditNoteRequestForPartiallyPaidInvoice
  extends CreditNoteRequest {
  creditNoteType: string;
}

export interface MappedBillingDocument {
  id: string;
  type: string;
  status: string;
  vatNumber?: string;
  date: number;
  dueDate?: number;
  paidAt?: number;
  total?: number;
  subTotal: number;
  tax: number;
  amountPaid: number;
  amountAdjusted?: number;
  creditsApplied?: number;
  dueAmount?: number;
  lineItems: BillingDocumentLineItem[];
  billingAddress?: BillingAddress;
  desc?: string;
  isCreditNote: boolean;
  negativeSymbol?: string;
  companyVat?: string;
  discount?: number;
  saleType?: string;
  receiptType?: string;
  zatcaStatus?: string;
  statusCode?: number;
  zatcaError?: ZatcaMessage[];
  formattedDate?: string;
  reference_invoice_id?: string;
  invoice_type?: string | null;
  credit_note_type?: string;
  errorBlob?: object;
  credit_note_visibility?: boolean;
  adjustment_credit_notes?: CbCreditNoteData[];
  issued_credit_notes?: CbCreditNoteData[];
  email?: string;
  additionalIdType?: SecondaryIDEnum;
  additionalIdValue?: string;
  createdAt?: Date;
  webhookTimestamp?: string;
  zatcaQrCode?: string;
  sequencedId?: string;
  oldSequencedId?: string;
  zatcaWarning?: any;
  sequencedReferenceId?: string;
  sequencedOldReferenceId?: string;
}

export interface IssueCreditNoteDTO {
  subtotal: number;
  discount: number;
  promotions: number;
  tax: number;
  total: number;
}

export interface CreditNoteLineItemDTO {
  name: string;
  quantity: number;
  adjustedPrice: number;
  totalPrice: number;
}

export interface BillingAddress {
  first_name?: string;
  last_name?: string;
  email?: string;
  company?: string;
  phone?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  state_code?: string;
  state?: string;
  country?: string;
  zip?: string;
  validation_status?: string;
}

export interface AttachedItem {
  id: string;
  parent_item_id: string;
  item_id: string;
  type: string;
  status?: string;
  quantity?: number;
  quantity_in_decimal?: string;
  billing_cycles?: number;
  charge_on_event: string;
  charge_once: boolean;
  created_at: number;
  resource_version?: number;
  updated_at?: number;
  channel?: string;
}

export interface UpdateMerchantResponseDTO {
  success: boolean;
  subscriptionId: string;
  subscriptionActive: boolean;
}

export interface AccountSubscriptionDetailsDTO {
  currentPlan: string;
  nextInvoiceIssueDate: Date;
  invoiceTotal: number;
  consumedLocations: number;
  consumedSKUs: number;
  allowedLocations: number;
  allowedSKUs: number;
  isLegacyPlan?: boolean;
  allowedApps: AllowedApp[];
  planShortDescriptions: string;
  attachedItems: AttachedItem[];
  addOnLocationCount: number;
  installedApps: InstalledApp[];
  subscriptionStatus: string;
  subscriptionStartDate: Date | string;
  subscriptionEndDate: Date | string;
  duration: number;
}

export interface EstimateDTO {
  planId: string;
  planDuartion: number;
  locationId: string;
  locations: number;
  chargeIds: string[];
  applicationIds: string[];
}

export interface ItemPriceDetailsDTO {
  itemPriceId: string;
  unit: string;
  period: number;
  name: string;
  price: number;
}

export interface AttachedItemDTO {
  itemId: string;
  type: string;
  name?: string;
  priceDetails: ItemPriceDetailsDTO[];
}

export interface AttachedItemDTOWithQuantity extends AttachedItemDTO {
  quantity: number;
}

export interface SubscriptionItem {
  itemPriceId: string;
  quantity: number;
  itemType?: ItemTypes;
}

export interface CheckoutEstimateReqDTO {
  subscriptionItems: SubscriptionItem[];
  couponIds: string[];
  nowInTrial: boolean;
  endOfTerm?: boolean;
  id: string;
  discountCode?: string;
  replace?: boolean;
  countryCode?: string;
}

export interface CheckoutRenewEstimateReqDTO {
  couponIds: string[];
  id: string;
  discountCode?: string;
}

export interface PlanAttachmentsResponse {
  addons: AttachedItemDTO[];
  charges: AttachedItemDTO[];
  includedInPlan: ItemEntitlements;
}

export interface ItemEstimate {
  itemPriceId: string;
  itemId: string;
  quantity: number;
  amount: number;
  taxAmount: number;
  discountAmount: number;
  itemLevelDiscountAmount: number;
  nextBillingAt: number;
  period: number;
  unit?: string;
  type?: ItemTypeEnum;
}

export interface CouponDetail {
  couponId: string;
  couponType: string;
  discountAmount: number;
  discountType?: string;
}
export interface CouponDictionary {
  [key: string]: string[];
}

export interface EstimateResponseDTO {
  estimatedItems: ItemEstimate[];
  couponDictionary?: CouponDictionary;
  totalCredits: number;
  amountPaid: number;
  promotionalCredit: number;
  invoiceLevelCouponDiscount: number;
  planType: string;
  couponApplicabilityStatus: string;
  couponDetails?: CouponDetail[];
}

export interface EstimateResponseWithCharges extends EstimateResponseDTO {
  chargesMapping?: { [itemId: string]: string };
}

export interface EstimateResponseLicenseKey {
  estimate: EstimateResponseDTO;
  shouldShippingAddressVisible: boolean;
  chargeItemDetails: { [itemId: string]: string };
}
export enum ItemTypeEnum {
  plan = 'plan',
  charge = 'charge',
  application = 'application',
  location = 'location',
}

export enum ItemTypes {
  Plan = 'plan',
  Addon = 'addon',
  Charge = 'charge',
}

export interface PaymentProofDTO {
  transaction: PaymentDTO[];
  merchant: { email: string };
}

export interface BillingHistoryResponseDTO {
  list: MappedBillingDocument[];
  email?: string;
}

export interface PartialPaymentDTO {
  isComplete: boolean;
  paymentMethod: PaymentType;
  payments: PaymentDTO[];
}

export interface PaymentScreenDTO {
  selectedPayment: PaymentType;
  payments: PaymentDTO[];
  length: number;
}

export interface PaymentScreenDTOWithValidity extends PaymentScreenDTO {
  isValid: boolean;
}
export interface SummaryLineItem {
  itemId: string;
  itemPriceId: string;
  quantity: number;
  originalQuantity: number;
  amount: number;
  period: number;
  taxAmount: number;
  itemLevelDiscount: number;
  discountAmount: number;
  type: ItemTypeEnum;
  appliedItemLevelCoupons: string[];
}

export interface SummaryDTO {
  applications?: SummaryLineItem[];
  location?: SummaryLineItem;
  charges?: SummaryLineItem[];
  plan?: SummaryLineItem;
  subTotal: number;
  taxAmount: number;
  creditAmount: number;
  amountPaid: number;
  total: number;
  discountCode?: string;
  discountAmount: number;
}

export type ZohoAccountInfomapper = {
  accountID: string;
  accountOwner: string;
  additionalIdentifier?: string;
  additionalIdentifierNumber?: string;
  brandName: string;
  businessName: string;
  category?: string;
  clientTaxNumber: string;
  clientsIBAN?: string;
  daysToUploadProduct?: string;
  email: string;
  employeeCount?: number;
  expectedOnboardingDate?: string;
  hasWebStore: boolean;
  isAdditionalTrainingRequired: boolean;
  isEmployee: boolean;
  isOutboundTrainingRequired: boolean;
  isStockCountRequired: boolean;
  needTrainingForAccounting: boolean;
  phoneNumber: string;
  previousUsedSystem?: string;
  reasonForUploadingProducts?: string;
  traineeContactNumber?: string;
  webStoreType?: string;
  employeeName?: string;
  employeePhone?: string;
  bestTimeToCommunicate?: string;
  integrateWithRewaaPay?: string;
};

export type AccountExistsResponse = {
  accountExists: boolean;
  crmAccount?: {
    crm: ZohoAccountInfomapper;
    foundCrmAccount: boolean;
  };
  crmAccountExists?: boolean;
  subscriptionId?: string;
  data?: BillingInfo;
  accountInfo?: AccountInfo;
};

export type UpdateMerchantInfoDTO = {
  crmAccountId: string;
  vatNumber: string;
  nonTaxableEntity: boolean;
  city: string;
  districtName: string;
  buildingNumber: string;
  streetName: string;
  additionalNumber: string;
  postalCode: string;
  platformEmail: string;
  accountStatus: string;
};

export interface CheckoutFormValueType {
  planId: string;
  planDuration: number;
  locations: number;
  applications: string[];
  charges: AttachedItemDTO[];
  coupons: Set<string>;
  isChargesEnabled: boolean;
  discountCode: string;
  shippingAddress?: string;
  summary: SummaryDTO;
}

export type AccountInfo = {
  id: number;
  phone: string;
  email: string;
  companyName: string;
  subscriptionID: string;
  subscriptionActive: boolean;
  subscriptionStatus: string;
};

export type BillingInfo = {
  vatNumber: string;
  city: string;
  countryCode: string;
  country?: string;
  streetName: string;
  postalCode: string;
  districtName: string;
  companyName?: string;
  additionalIdentifier?: string;
  businessCategory?: string;
  additionalIdentifierNumber?: string;
  previousUsedSystem?: string;
  buildingNumber?: string;
  additionalNumber?: string;
  integrateWithRewaaPay?: string;
  clientsIBAN?: string;
  hasWebStore?: boolean;
  webStoreType?: string;
  isRewaaPayIncluded?: boolean;
  isStockCountRequired?: boolean;
  isInPersonTrainingRequired?: boolean;
  isAdditionalTrainingRequired?: boolean;
  isOutboundTrainingRequired?: boolean;
};

export type BillingInfoWithValidity = BillingInfo & {
  isValid: boolean;
};

export type BankTransferSubscriptionItems = SubscriptionItem & {
  type: string;
  basePlanId: string;
  period: number;
  price: number;
  itemLevelDiscount?: number;
  /** @testing */
  discountAmount: number;
  originalQuantity: number;
  zohoOnly?: boolean;
  firebaseOnly?: boolean;
};

export interface UpdateSubscriptionItemsDTO {
  subscriptionItems: BankTransferSubscriptionItems[];
  nowInTrial: boolean;
  checkOutFlow?: string;
  replace?: boolean;
  isAccountActive?: boolean;
  nextBillingAt?: string;
  couponIds: string[];
}
export interface UpdateSubscriptionItemsDTOV1 {
  subscriptionItems: BankTransferSubscriptionItems[];
  nowInTrial: boolean;
  checkOutFlow?: string;
  id: string;
  replace?: boolean;
  isAccountActive?: boolean;
  userEmail: string;
  nextBillingAt?: string;
  couponIds: string[];
}

export interface BankTransferDTO {
  id: string;
  userEmail: string;
  nowInTrial: boolean;
  payments: PaymentDTO[] | null;
  subscriptionItems: BankTransferSubscriptionItems[];
  amount: number;
  discountAmount: number;
  checkOutFlow: string;
  isPartial?: boolean;
  /** @testing */
  isAccountActive: boolean;
}

export interface BankTransferDTOV1 {
  id: string;
  userEmail: string;
  nowInTrial: boolean;
  invoiceURL: string | null;
  subscriptionItems: BankTransferSubscriptionItems[];
  amount: number;
  discountAmount: number;
  isPartial?: boolean;
}

export interface AdminOnlinePaymentDTOV1 extends BankTransferDTOV1 {
  paymentMethod: PaymentType;
  hardwareShippingAddress?: string;
  adminEmail: string;
}

export type RegisterNewMerchantDTO = {
  email: string;
  password: string;
  phoneNumber: string;
  companyName: string;
  hasToCheckCRM: boolean;
};

export type PlatformDetails = {
  id: number;
  businessName: string;
  email: string;
  phoneNumber: string;
  subscriptionID: string;
  subscriptionActive: boolean;
  subscriptionStatus: string;
  hasPlatformAccount: boolean;
  linkingNewAccount?: boolean;
  crmAccountExists?: boolean;
};

export type CRMDetails = {
  businessName: string;
  email: string;
  phoneNumber: string;
  accountID: number;
  accountOwner: string;
};

export interface CouponNotValidResponse {
  couponApplicabilityStatus?: CouponApplicabilityStatus;
}

export type EstimateV2Response = EstimateResponseDTO | CouponNotValidResponse;

export interface PickUpDTO {
  destinationPoint: string;
  date: Date;
  note?: string;
}

export interface DeliveryDTO {
  address: string;
  note?: string;
}

export interface ShippingAddress {
  deliveryType: ShippingAddressTypes;
  details: DeliveryDTO | PickUpDTO;
}

export interface FeatureDTO {
  featureId: string;
  itemId: string | null;
}

export interface InvoiceDto {
  vatNumber: string;
  city: string;
  countryCode?: string;
  country?: string;
  streetName: string;
  postalCode: string;
  districtName: string;
  companyName?: string;
  additionalIdentifier?: string;
  additionalIdentifierNumber?: string;
  buildingNumber?: string;
  additionalNumber?: string;
  webhookTimestamp?: number;
}

export type InvoiceDtoWithValidity = InvoiceDto & {
  isValid: boolean;
};

export const MapAdditionalIdentifier = {
  'Commercial Registration': 'Commercial Registration',
  'Freelance License': 'Freelance License',
  'Investment License': 'Investment License',
  'MLSD licenses': 'MLSD License',
  'MOMRA licenses': 'MOMRA License',
  'Municipal License': 'Municipal License',
  'Other ID': 'Other ID',
  'SAGIA licenses': 'SAGIA License',
  'MOMRA License': 'MOMRA License',
};

export interface RetryAndResubmissionPayload {
  id: string;
  type: string;
  invoiceData?: InvoiceDto;
}

export interface MappedBillingDocumentWithPaymentReceiptData
  extends MappedBillingDocument {
  customerId?: string;
  lastUpdated?: number;
  txnData?: LinkedPaymentWithPaymentMethod[];
  totalCollectedAmount?: number;
  excessBalance?: number;
  printingSubtotal?: number;
  totalDiscount?: number;
  totalTaxableAmount?: number;
}

export interface LinkedPaymentWithPaymentMethod extends LinkedPayment {
  bankName?: string;
  paymentMethod: string;
}

export interface CreateCreditNoteResponseDTO {
  creditNoteId: string | undefined;
}
